@import url('mode-nav.css');
@import url('header.css');
@import url('footer.css');

@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans-Regular.woff2') format('woff2');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'OpenSans Bold';
  src: url('../../fonts/OpenSans-Bold.woff2') format('woff2');
  font-weight: bold;
  font-display: swap;
}
@font-face {
  font-family: 'Source Code Pro';
  src: url('../../fonts/SourceCodePro-Regular.woff2') format('woff2');
  font-weight: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Source Code Pro';
  src: url('../../fonts/SourceCodePro-Bold.woff2') format('woff2');
  font-weight: bold;
  font-display: swap;
}

:root {
  --colors-white: rgba(255, 255, 255, 1);
  --colors-black: rgba(0, 0, 0, 1);
  --colors-yellow: rgba(251, 255, 215, 1);
  --colors-red: rgba(207, 60, 58, 1);
  --colors-orange: rgb(255, 131, 48);

  --colors-primary: rgb(66, 155, 113);
  --colors-primary-hover: color-mix(in srgb, var(--colors-primary) 10%, transparent);
  --colors-primary-high-contrast: rgba(70, 129, 90, 1);
  --colors-chess-board-light: rgba(227, 193, 111, 1);
  --colors-chess-board-dark: rgba(184, 139, 74, 1);
  --colors-highlight-wrong: rgba(207, 60, 58, 0.4);

  --colors-background: var(--colors-white);

  --colors-text-color: var(--colors-black);

  --colors-button-secondary: rgb(175, 175, 175);

  @media (prefers-color-scheme: dark) {
    --colors-yellow: #fabc2a;
    --colors-red: rgb(173, 3, 0);
    --colors-primary-high-contrast: var(--colors-primary);
    --colors-background: rgba(35, 35, 35, 1);
    --colors-background: rgb(30, 30, 30);
    --colors-text-color: var(--colors-white);
  }
}

[data-theme='dark'] {
  --colors-yellow: #fabc2a;
  --colors-red: rgb(173, 3, 0);
  --colors-primary-high-contrast: var(--colors-primary);
  --colors-background: rgba(35, 35, 35, 1);
  --colors-background: rgb(30, 30, 30);
  --colors-text-color: var(--colors-white);
}

[data-theme='light'] {
  --colors-white: rgba(255, 255, 255, 1);
  --colors-black: rgba(0, 0, 0, 1);
  --colors-yellow: rgba(251, 255, 215, 1);
  --colors-red: rgba(207, 60, 58, 1);
  --colors-orange: rgb(255, 131, 48);

  /* --colors-primary: rgba(81, 152, 114, 1); */
  --colors-primary: rgb(66, 155, 113);
  --colors-primary-hover: color-mix(in srgb, var(--colors-primary) 10%, transparent);
  --colors-primary-high-contrast: rgba(70, 129, 90, 1);
  --colors-chess-board-light: rgba(227, 193, 111, 1);
  --colors-chess-board-dark: rgba(184, 139, 74, 1);
  --colors-highlight-wrong: rgba(207, 60, 58, 0.4);

  --colors-background: var(--colors-white);

  --colors-text-color: var(--colors-black);
}

* {
  margin: 0px;
  box-sizing: border-box;
  font-family: 'OpenSans', sans-serif;
}

h1 {
  font-family: 'OpenSans', sans-serif;
  font-weight: bold;
}

html {
  font-size: 16px;
}

body {
  background-color: var(--colors-background);
  overflow-x: hidden;
  color: var(--colors-text-color);
}

a {
  color: var(--colors-text-color);
}

.hidden {
  display: none !important;
}

.flipped {
  rotate: 180deg !important;

  .square {
    rotate: 180deg !important;
  }
  .square[data-square='a1'] {
    &.show-hint {
      &::before {
        transform: translateY(270%) translateX(300%) !important;
      }
    }
  }
  .square[data-square='h8'] {
    &.show-hint {
      &::before {
        transform: translateY(270%) translateX(-300%) !important;
      }
    }
  }
}

.desktop-only {
  display: none;
}

input[type='checkbox'] {
  width: 25px;
  height: 25px;
}

#toast {
  position: fixed;
  left: 50%;
  transform: translate(-50%, 0px);
  bottom: 60px;
  padding: 10px 20px;
  opacity: 1;
  background-color: var(--colors-chess-board-light);
  color: var(--colors-white);
  font-weight: bold;
  border-radius: 10px;
  transition: all 200ms ease-in-out;
  text-align: center;
  font-size: 1.3rem;

  &.toast-hidden {
    transform: translate(-50%, 40px);
    opacity: 0;
    transition: all 100ms ease-in-out;
  }
}

.timer-switch-container {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}

@media (min-width: 1060px) {
  .mobile-only {
    display: none !important;
  }
  .desktop-only {
    display: block;
  }
  #toast {
    font-size: 1.5rem;
  }
  .timer-switch-container {
    position: absolute;
    left: -440px;
    bottom: 70px;
  }
}
