@import url('../shared/common.css');

main {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 10px;

  svg {
    width: 160px;
    transform: translateX(-10px) rotate(-140deg);
    margin: 20px 0px 60px;
  }

  h1 {
    font-size: 1.3rem;
    font-weight: normal;
  }
  h2 {
    font-size: 1.3rem;
    font-weight: normal;
  }

  nav {
    margin-top: 80px;
    display: grid;
    grid-template-areas:
      'coordinates memory'
      'home home';
    grid-template-columns: 1fr 1fr;
    width: 100%;
    max-width: 500px;
    gap: 30px 20px;
    align-items: stretch;

    .link-coordinates {
      grid-area: coordinates;
    }
    .link-memory {
      grid-area: memory;
    }
    .link-home {
      grid-area: home;
    }

    .link-coordinates,
    .link-memory {
      display: block;
      font-size: 1.1rem;
      height: 75px;
      padding: 0px 10px;
      border-radius: 5px;
      background-color: var(--colors-primary);
      color: white;
      border: none;
      cursor: pointer;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        background-color: var(--colors-primary-hover);
        border: 2px solid var(--colors-primary);
        color: var(--colors-primary);
        transition: all 50ms ease-in-out;
      }
    }
    .link-home {
      font-size: 1rem;
    }
  }
}

@media (min-width: 412px) {
  main {
    nav {
      .link-coordinates,
      .link-memory {
        height: 60px;
      }
    }
  }
}

@media (min-width: 500px) {
  main {
    svg {
      width: 200px;
    }
    nav {
      max-width: 550px;
      gap: 30px 40px;
    }
  }
}

@media (min-width: 600px) {
  main {
    svg {
      margin: 3 0px 0px 90px;
    }
    h1 {
      font-size: 2.2rem;
    }
    h2 {
      font-size: 2.2rem;
    }
    nav {
      margin-top: 110px;
      .link-coordinates,
      .link-memory {
        font-size: 1.4rem;
        height: 75px;
      }
    }
  }
}

@media (min-width: 1200px) {
  main {
    margin-top: 100px;
  }
}
