header {
  width: 100vw;
  padding: 20px 15px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;

  .logo-and-title-container {
    display: flex;
    align-items: flex-end;
    gap: 10px;

    svg {
      width: 30px;
      stroke: var(--colors-text-color);
      stroke-width: 20px;
      margin-bottom: 5.5px;
      display: none;
    }

    .title {
      text-decoration: none;
      font-size: 1.3rem;
      font-family: 'Source Code Pro', monospace;

      .dotcom {
        font-size: 0.5em;
        font-family: 'Source Code Pro', monospace;
        display: none;
      }
    }
  }

  .header-theme-and-github-container {
    display: flex;
    flex-flow: row;
    align-items: center;
    gap: 15px;

    .theme-selector-container {
      border: 1px solid var(--colors-text-color);
      border-radius: 100px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: var(--colors-background);
      height: 35px;
      width: 60px;

      .theme-selector-svg-container {
        position: absolute;
        width: 30px;
        height: 30px;
        border-radius: 100px;
        left: 2px;

        svg {
          position: absolute;
          left: 3px;
          top: 3px;
          width: 24px;
          fill: var(--colors-text-color);
        }
      }
    }

    .header-github-container {
      margin-bottom: -3px;

      a {
        text-decoration: none;
        cursor: pointer;
      }

      svg {
        width: 35px;
        path {
          fill: var(--colors-text-color);
        }
      }
    }
  }
}

@media (min-width: 500px) {
  header {
    .logo-and-title-container {
      svg {
        display: block;
      }
      .title {
        .dotcom {
          display: inline-block;
        }
      }
    }
  }
}

@media (min-width: 765px) {
  header {
    padding: 30px 40px;

    .title {
      font-size: 1.5rem;
    }
    .header-theme-and-github-container {
      gap: 35px;
      .header-github-container {
        svg {
          width: 40px;
        }
      }
    }
  }
}

[data-theme='dark'] {
  header {
    .header-theme-and-github-container {
      .theme-selector-container {
        .theme-selector-svg-container {
          transform: translateX(25px);
          .theme-sun {
            display: none;
          }
          .theme-moon {
            display: block;
          }
        }
      }
    }
  }
}

[data-theme='light'] {
  header {
    .header-theme-and-github-container {
      .theme-selector-container {
        .theme-selector-svg-container {
          transform: translateX(0px);
          .theme-sun {
            display: block;
          }
          .theme-moon {
            display: none;
          }
        }
      }
    }
  }
}
