.nav-wrapper {
  margin: 0px;
  width: 100%;
  max-width: 600px;
  padding: 15px 15px 0px;

  .nav-wrapper-heading {
    width: 100%;
    margin: 0px 0px 20px;

    .mobile-nav-heading {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
      font-size: 1rem;
      svg {
        fill: var(--colors-primary);
      }

      .mobile-nav-heading-button {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
      }
      .mobile-nav-heading-current-mode {
        display: flex;
        flex-direction: row;
        align-items: center;
        span {
          color: var(--colors-primary-high-contrast);
          margin-left: 5px;
          font-family: 'OpenSans';
        }
      }
    }
  }

  nav {
    background-color: var(--colors-background);
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all 150ms ease-out;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      transition: all 100ms ease-in;
      pointer-events: all;
    }
    a {
      font-size: 1.6rem;
      padding: 5px 15px;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      color: color-mix(in srgb, var(--colors-text-color) 80%, var(--colors-white));
      outline: none;
      text-decoration: none;
      transform: translateY(-40px);
      &.active {
        color: var(--colors-white);
        background-color: var(--colors-primary);
      }
    }

    .mobile-nav-close-button {
      position: absolute;
      bottom: 40px;
      right: 50%;
      transform: translateX(50%);
      padding: 50px;
      svg {
        width: 50px;
        opacity: 0.4;
        fill: var(--colors-text-color);
      }
    }
  }
}

@media (min-width: 620px) {
  .nav-wrapper {
    padding: 0px;
  }
}

@media (min-width: 765px) {
  .nav-wrapper {
    nav {
      button {
        font-size: 1.5rem;
        padding: 10px 15px;
        border-radius: 7px;
      }
    }
  }
}

@media (min-width: 1060px) {
  .nav-wrapper {
    flex-flow: column;
    align-items: flex-start;
    max-width: 400px;
    margin-top: 0px;

    .nav-wrapper-heading {
      font-size: 2rem;
      font-weight: medium;
      margin: 0px 0px 20px;
    }

    nav {
      position: static;
      display: flex;
      flex-flow: column;
      align-items: flex-start;
      justify-content: space-evenly;
      gap: 0px;
      opacity: 1;
      pointer-events: all;
      margin: 0px 0px 0px;
      width: 100%;
      background-color: transparent;
      height: auto;

      a {
        font-size: 1.2rem;
        transform: translate(0px);

        &.active {
          color: var(--colors-primary);
          background-color: transparent;

          &::before {
            content: '\2023';
            position: absolute;
            font-size: 3rem;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            color: var(--colors-primary);
          }
        }

        &:hover {
          color: var(--colors-primary-high-contrast);
          &::before {
            content: '\2023';
            position: absolute;
            font-size: 3rem;
            left: -15px;
            top: 50%;
            transform: translateY(-50%);
            color: color-mix(in srgb, var(--colors-primary) 40%, transparent);
          }
        }
      }
    }
  }
}
